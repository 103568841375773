<template>
  <router-view />
</template>

<script>
export default {
  name: 'Landing',
  props: {
    lang: {
      type: String,
      default: 'en',
    },
  },
  data() {
    return {
      keywords:
        'Создание планировок, 2D планировки, 3D планировки, виртуальные туры, ИИ для недвижимости, быстрые планировки, планировка за 24 часа, Hart Estate, ИИ в недвижимости, реклама недвижимости, услуги для застройщиков, 3D визуализация недвижимости',
    };
  },
  mounted() {
    this.updateMetaTags();

    if (window.location.hostname === 'stage.getfloorplan.com' || window.location.hostname === 'stage.hart-estate.ru') {
      this.removeWebsiteFromSearchIndex();
    }
  },
  methods: {
    removeWebsiteFromSearchIndex() {
      const meta = document.querySelector('meta[name="robots"]');
      if (meta) {
        meta.content = 'noindex, nofollow';
      }
    },
    updateMetaTags() {
      document.querySelectorAll('meta[name="description"], meta[name="keywords"]').forEach((el) => el.remove());

      if (this.lang === 'ru') {
        document.title = '2D и 3D планировки за 24 часа с виртуальным туром с помощью ИИ | Hart Estate';

        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = 'Готовые 2D и 3D планы с виртуальным туром всего за 24 часа по цене в 3 раза дешевле остальных на рынке.';
        document.head.appendChild(metaDescription);

        const metaKeywords = document.createElement('meta');
        metaKeywords.name = 'keywords';
        metaKeywords.content = this.keywords;
        document.head.appendChild(metaKeywords);
      } else {
        document.title = 'Creating 2D and 3D floor plans with AI';

        const metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = 'Download a 2D floor plan to get great 3D renderings with furniture and a virtual tour.';
        document.head.appendChild(metaDescription);

        const metaKeywords = document.createElement('meta');
        metaKeywords.name = 'keywords';
        metaKeywords.content = '3d floor plan cost, floor plan rendering, create 3d floor plans, 3d floor plan, 3d floor plan small house, home floor plan builder, 3d floorplan maker, convert floorplan, floorplan converter';
        document.head.appendChild(metaKeywords);
      }
    },
  },
};
</script>

<style>
body {
  background-color: white;
}
</style>
